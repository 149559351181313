.button-block {
  width: 350px;
  margin: 30px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.step1 .MuiOutlinedInput-notchedOutline {
  border-width: 2px !important;
}
.step1 .little-preloader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
