.act-item {
  position: relative;
  border: 2px solid #C5C5C5;
  border-radius: 4px;
  padding: 10px 30px 10px 10px;
  margin-bottom: 10px;

  img {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
  }
}