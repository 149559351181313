.status-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto 50px auto;

}
.status-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}