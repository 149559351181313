.App {
  max-width: 1200px;
  margin: auto;
}
.container {
  max-width: 1000px;
  margin: auto;
}
.footer-button-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}