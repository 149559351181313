header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
      cursor: pointer;
    }

    p {
      margin-left: 10px;
    }
  }
}